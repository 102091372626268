import React from "react";
import styled from "@emotion/styled";

export const ContainerWrapper = (props) => {
  return <SContainer className="container">{props.children}</SContainer>;
};

const SContainer = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: auto;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 95%;
  }
`;
