import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";

import { ContainerWrapper } from "../wrapper/containerWrapper";

export const Breadcrumbs = (props) => {
  return (
    <SBreadcrumbs>
      <ContainerWrapper>{props.children}</ContainerWrapper>
    </SBreadcrumbs>
  );
};

const SBreadcrumbs = styled.div`
  margin-top: 80px;
  padding: 1rem 0;
  background-color: ${Color.primary};
  .container {
    display: flex;
    align-items: center;
    a {
      line-height: 1;
      text-decoration: none;
      color: ${Color.light};
      font-size: 0.9rem;
      font-weight: 500;
    }
    span {
      line-height: 1;
      text-decoration: none;
      color: ${Color.light};
      font-size: 0.9rem;
      font-weight: 500;
      margin: 0 1rem;
    }
  }

  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    width: 100%;
    .container {
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
    margin-top: 64px;
  }
`;
