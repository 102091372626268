import React from "react";
import styled from "@emotion/styled";
import { Color } from "../../utils/color";
import { Link } from "gatsby";

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { EnLayout } from "../../components/layout/enLayout";

const EnRecruitPage = () => {
  return (
    <EnLayout title="RECRUIT | LIME" jpUrl="/recruit/" enUrl="/en/recruit/">
      <Breadcrumbs>
        <Link to="/en/">HOME</Link>
        <span>/</span>
        <Link to="/en/recruit/">RECRUIT</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>EN RECRUIT</p>
      </SSection>
    </EnLayout>
  );
};

export default EnRecruitPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
